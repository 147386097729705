import React, { type FC } from 'react';
import { useTheme } from '../ThemeProvider/ThemeProvider';
import logo_light from '../../assets/images/logo_light.png';
import logo_dark from '../../assets/images/logo_dark.png';

export const LogoIcon: FC = () => {
  const { theme } = useTheme();
  const style = { width: '54px', height: '54px' };
  const themeToLogo: Record<string, string> = {
    light: logo_dark,
    dark: logo_light
  };

  return (
    <img src={themeToLogo[theme ?? 'light']} style={style} alt='logo' />
  )
}
