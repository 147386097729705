import React, { type FC } from 'react';

export const DefaultLogo: FC = () => {
  const fillColor = 'var(--CFD-theme-System-OnSurface)';

  return (
    <svg width="160" height="40" viewBox="0 0 160 40" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M28.7453 31.5832C23.3232 31.5832 19.5171 27.7935 19.1178 21.6592L11.3808 29.3751C14.357 35.9001 20.74 40 28.8513 40C37.9797 40 44.8619 34.9512 47.0457 26.6708H36.7755C35.1907 29.9581 32.2955 31.5832 28.7453 31.5832Z" fill={fillColor}/>
      <path d="M10.657 22.143L19.3112 17.9439L10.4199 26.7762L0 10.6931L10.657 22.143Z" fill={fillColor}/>
      <path d="M28.8514 0C18.394 0 10.7881 6.87859 9.70868 16.9391L11.4994 18.6448L19.723 15.1155C21.0521 10.9474 24.3652 8.47263 28.7453 8.47263C32.2956 8.47263 35.1907 10.1039 36.7756 13.3602H47.0457C44.8619 5.10467 37.9798 0 28.8514 0Z" fill="#8224E3"/>
      <path d="M48.8738 30.5722L53.3787 29.617C53.9236 32.1559 56.0035 33.4253 59.6182 33.4253C60.9567 33.5106 62.2878 33.1695 63.418 32.4515C63.8231 32.1886 64.1564 31.8301 64.3883 31.4081C64.6202 30.986 64.7434 30.5135 64.747 30.0326C64.7445 29.3371 64.5267 28.6593 64.1231 28.0912C63.7071 27.4957 62.8461 27.0305 61.54 26.6956L56.6232 25.4303C55.2997 25.1478 54.0339 24.6445 52.8796 23.9417C52.0355 23.3409 51.3679 22.5273 50.9453 21.5847C50.4588 20.5743 50.2092 19.4673 50.2153 18.347C50.1801 17.293 50.3944 16.2455 50.8409 15.2889C51.2874 14.3322 51.9537 13.4931 52.786 12.8392C54.6599 11.4056 56.981 10.6724 59.3437 10.7676C61.4138 10.7328 63.4579 11.2307 65.2774 12.2127C66.0913 12.6225 66.8103 13.1966 67.3883 13.8983C67.9663 14.5999 68.3907 15.4138 68.6342 16.2878L64.2042 17.367C63.5304 15.4318 61.9476 14.4643 59.456 14.4643C58.2847 14.3893 57.1221 14.7083 56.1553 15.3698C55.7962 15.6341 55.5054 15.9795 55.3069 16.3774C55.1084 16.7753 55.0078 17.2144 55.0135 17.6586C55.0135 19.1161 56.2114 20.1396 58.6012 20.7598L62.9688 21.8514C65.6227 22.5131 67.4009 23.491 68.3036 24.7852C69.1968 26.0373 69.6725 27.5363 69.6638 29.0712C69.7083 30.216 69.4713 31.3544 68.9733 32.3876C68.4754 33.4209 67.7315 34.3178 66.8061 35.0008C64.9051 36.4439 62.4384 37.1655 59.4061 37.1655C57.0699 37.2199 54.7593 36.6729 52.6986 35.5776C51.7484 35.0504 50.9182 34.3337 50.2599 33.4723C49.6017 32.6109 49.1298 31.6235 48.8738 30.5722Z" fill={fillColor}/>
      <path d="M88.0266 27.8555L92.3319 28.3269C92.1293 30.7246 91.0663 32.97 89.3369 34.6534C88.4336 35.4936 87.3706 36.1459 86.2101 36.572C85.0497 36.998 83.8154 37.1893 82.5796 37.1344C80.5197 37.2246 78.4931 36.5948 76.8517 35.3543C75.3185 34.0859 74.1515 32.4357 73.4699 30.5722C72.6557 28.4478 72.2514 26.19 72.2782 23.9169C72.2782 20.0217 73.2099 16.8502 75.0735 14.4022C75.8955 13.3794 76.9162 12.5315 78.0748 11.9091C79.2334 11.2866 80.5061 10.9023 81.8172 10.779C83.1283 10.6557 84.451 10.796 85.7064 11.1915C86.9619 11.587 88.1244 12.2296 89.1248 13.0811C90.8219 14.6441 91.8909 16.8812 92.3319 19.7922L88.0266 20.245C87.2321 16.3829 85.4851 14.4519 82.7854 14.4519C81.9531 14.44 81.134 14.6589 80.4198 15.084C79.7057 15.509 79.1248 16.1235 78.7423 16.8584C77.7731 18.4628 77.2905 20.7908 77.2947 23.8425C77.2989 26.8941 77.8147 29.2345 78.8421 30.8637C79.2508 31.5852 79.8454 32.1855 80.5647 32.603C81.2841 33.0205 82.1022 33.2401 82.9352 33.2393C83.5958 33.2439 84.2494 33.1034 84.8491 32.8278C85.4488 32.5523 85.9798 32.1485 86.4043 31.6452C87.3531 30.5959 87.9239 29.2627 88.0266 27.8555Z" fill={fillColor}/>
      <path d="M116.017 36.7437H111.063L109.01 30.6528H99.1017L97.0801 36.7437H93.1181L101.666 11.1645H107.382L116.017 36.7437ZM107.756 26.9561L104.012 15.5373L100.269 26.9561H107.756Z" fill={fillColor}/>
      <path d="M134.954 36.7437H119.112V11.1645H123.997V32.5632H134.954V36.7437Z" fill={fillColor}/>
      <path d="M143.009 36.7437H138.367V11.1645H148.912C151.919 11.1645 154.149 11.9171 155.6 13.4222C156.301 14.1231 156.855 14.9557 157.229 15.8716C157.603 16.7874 157.789 17.7681 157.778 18.7564C157.818 19.8162 157.628 20.872 157.221 21.8523C156.814 22.8326 156.199 23.7144 155.419 24.4379C153.789 25.9118 151.644 26.6932 149.442 26.615H143.009V36.7437ZM143.009 22.6454H148.5C149.689 22.7259 150.865 22.3484 151.782 21.5909C152.16 21.2336 152.457 20.7993 152.652 20.3177C152.847 19.8361 152.935 19.3184 152.911 18.7998C152.923 17.8074 152.547 16.8491 151.863 16.1265C151.164 15.374 149.904 14.9977 148.082 14.9977H143.009V22.6454Z" fill={fillColor}/>
    </svg>
  )
}
